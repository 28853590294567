//todo: better copy and style for thank you message, fade out animation
//  which requires using grid to stick form and thank you msg in same place.
//todo: validation UX for form
//todo: also, move this specialized component to the amaris project

import React, {useContext, useState} from 'react';
import styled, {ThemeContext} from 'styled-components';
import {useForm} from 'react-hook-form';
import Input from '@hoffman/shared/src/components/Input/input';
import signup, {signupData} from '@hoffman/amaris/src/api/signup';
import ReCAPTCHA from "react-google-recaptcha";

function getCookie(cname: string) {
  if( typeof document !== `undefined`) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for(let i = 0; i <ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
  }
  return "untagged";
}

const ContactPageSection = () => {
  const theme = useContext(ThemeContext);
  const {register, handleSubmit, watch, errors} = useForm();
  const [done, setDone] = useState(false);
  const [bork, setBork] = useState(false);
  const recaptchaRef = React.useRef();

  // get referral codes
  const utm_source = getCookie('utm_source');
  const utm_medium = getCookie('utm_medium');
  const utm_campaign = getCookie('utm_campaign');

  const onSubmit = handleSubmit((data: signupData) => {
    const token = recaptchaRef.current.execute();
    if (token) {
      signup(data).then((res) => {
        if (res.success) {
          setDone(true);
        } else {
          setBork(true);
          console.error({signupErr: res.err});
        }
      });
    } else {
      setBork(true);
    }
  });

  return (
    <Container>
      <DetailContainer>
        <div>Amaris Sales Gallery:</div>
        <div>
          <a href="tel:202.918.7180">202.918.7180</a>
        </div>
        <div>760 Maine Avenue, SW </div>
        <div>Washington, DC 20024</div>
      </DetailContainer>
      {!done && (
        <Form onSubmit={onSubmit}>
          <div css='margin-bottom: -50px !important;'>Contact our sales team to learn more about Amaris.</div>
          <Input
            placeholder="utm_source"
            placeholderColor="grey"
            borderColor="none"
            displaySetting="none"
            required={true}
            arrow={false}
            elProps={{
              ref: register({required: true}),
              name: 'utm_source',
              value: utm_source,
              readOnly: true,
              hidden: true
            }}
          />
               <Input
            placeholder="utm_medium"
            placeholderColor="grey"
            borderColor="none"
            displaySetting="none"
            required={true}
            arrow={false}
            elProps={{
              ref: register({required: true}),
              name: 'utm_medium',
              value: utm_medium,
              readOnly: true,
              hidden: true
            }}
          />
          <Input
            placeholder="utm_campaign"
            placeholderColor="grey"
            borderColor="none"
            displaySetting="none"
            required={true}
            arrow={false}
            elProps={{
              ref: register({required: true}),
              name: 'utm_campaign',
              value: utm_campaign,
              readOnly: true,
              hidden: true
            }}
          />
          <Input
            placeholder="First Name"
            placeholderColor="grey"
            borderColor="grey"
            required={true}
            arrow={false}
            elProps={{
              ref: register({required: true}),
              name: 'firstName',
            }}
          />
          <Input
            placeholder="Last Name"
            placeholderColor="grey"
            borderColor="grey"
            required={true}
            arrow={false}
            elProps={{
              ref: register({required: true}),
              name: 'lastName',
            }}
          />
          <Input
            placeholder="Email Address"
            placeholderColor="grey"
            borderColor="grey"
            required={true}
            arrow={false}
            elProps={{
              ref: register({required: true}),
              name: 'email',
            }}
          />
          <Input
            placeholder="Phone Number"
            placeholderColor="grey"
            borderColor="grey"
            required={true}
            arrow={false}
            elProps={{
              ref: register({required: true}),
              name: 'phone',
            }}
          />
          <div>
            <ReCAPTCHA
              ref={recaptchaRef}
              size="invisible"
              sitekey={process.env.GATSBY_RECAPTCHA_KEY}
            />
          </div>
          <p>*required</p>

          <ContactFormButton type="submit">submit</ContactFormButton>
        </Form>
      )}
      {done && (
        <DetailContainer>
          <div id="thank-you-message">
            Thank you for your interest in Amaris. We look forward to connecting
            and sharing more about these inspiring waterfront residences.
          </div>
        </DetailContainer>
      )}
    </Container>
  );
};

export default ContactPageSection;

const Container = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 50% 50%;
  color: ${({theme}) => theme.colors.text};

  p {
    font-size: ${({theme}) => theme.fontSizes[1]}px;
  }

  a {
    color: ${({theme}) => theme.colors.text};
    transition: text-decoration-color 0.15s ease-in;
    text-decoration-color: transparent;
    &:hover {
      text-decoration-color: ${({theme}) => theme.colors.text};
    }
  }

  @media (max-width: ${({theme}) => theme.breakpoints[1]}) {
    grid-template-columns: 100%;
  }
`;

const DetailContainer = styled.div`
  max-width: 500px;
  font-size: ${({theme}) => theme.fontSizes[5]}px;
  font-family: ${({theme}) => theme.fontFaces.heading};
  div {
    line-height: ${({theme}) => theme.lineHeights.body};
  }

  @media (max-width: ${({theme}) => theme.breakpoints[1]}) {
    font-size: ${({theme}) => theme.fontSizes[4]}px;
    margin-bottom: 40px;
  }
`;

const ContactFormButton = styled.button`
  display: inline-block;
  background-color: ${({theme}) => theme.colors.background};
  border: ${({theme}) => theme.colors.text} 1px solid;
  color: ${({theme}) => theme.colors.text};

  font-family: ${({theme}) => theme.fontFaces.default};
  font-size: ${({theme}) => theme.fontSizes[1]}px;
  font-weight: ${({theme}) => theme.fontWeights.light};
  text-transform: uppercase;
  text-decoration: none;
  letter-spacing: ${({theme}) => theme.letterSpacings[5]}px;

  padding: ${({theme}) => `${theme.spaces[2]}px ${theme.spaces[4]}px`};

  transition-property: background-color color;
  transition-duration: 0.15s;
  transition-timing-function: ease-in;

  &:hover {
    background-color: ${({theme}) => theme.colors.text};
    color: ${({theme}) => theme.colors.background};
  }

  align-self: flex-end;

  @media screen and (max-width: ${({theme}) => theme.breakpoints[0]}) {
    &:hover {
      background-color: initial;
      color: initial;
    }
    align-self: stretch;
  }
`;

const Form = styled.form`
  font-family: ${({theme}) => theme.fontFaces.default};
  font-weight: ${({theme}) => theme.fontWeights.light};
  line-height: ${({theme}) => theme.lineHeights.body};

  display: flex;
  flex-direction: column;
  align-items: stretch;

  div {
    margin-bottom: 25px;
    font-size: ${({theme}) => theme.fontSizes[3]}px;
  }

  input {
    padding: 10px 0;
    border-bottom: 0px solid white;
    color: ${({theme}) => theme.colors.text};

    &::placeholder {
      color: ${({theme}) => theme.colors.text};
    }
  }

  @media (max-width: ${({theme}) => theme.breakpoints[1]}) {
    input {
      font-size: ${({theme}) => theme.fontSizes[2]}px;
    }
  }

  @media (max-width: ${({theme}) => theme.breakpoints[0]}) {
    input,
    div {
      font-size: ${({theme}) => theme.fontSizes[1]}px;
    }
  }
`;
