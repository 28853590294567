import React from 'react';
import ContactPageSection from '@hoffman/shared/src/components/page-sections/ContactPageSection';
import Section from '@hoffman/shared/src/components/Section';
import SectionBackgroundGroup from '@hoffman/shared/src/components/SectionBackgroundGroup';
import SectionPadding from '@hoffman/shared/src/components/SectionPadding';

const ContactContactSectionBlock = (props) => {
  return (
    <Section themeName="DARK">
      <SectionBackgroundGroup>
        <SectionPadding py="tall">
          <ContactPageSection />
        </SectionPadding>
      </SectionBackgroundGroup>
    </Section>
  );
};

export default ContactContactSectionBlock;
