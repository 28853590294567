import React from 'react';
import BaseLayout from '@hoffman/shared/src/components/BaseLayout';
import SEO from '../../../../packages/docs/src/components/seo';
import NavbarBlock from '@hoffman/amaris/src/blocks/NavbarBlock';
import ContactInteriorHeadlineBlock from '~blocks/ContactInteriorHeadlineBlock';
import ContactContactSectionBlock from '~blocks/ContactContactSectionBlock';
import FooterBlock from '~blocks/FooterBlock';
import {useStaticQuery, graphql} from 'gatsby';
import SectionPadding from '@hoffman/shared/src/components/SectionPadding';
import styled from 'styled-components';

const SecondPage = () => {
  const {dataJson} = useStaticQuery(
    graphql`
      query {
        dataJson(slug: {eq: "contact"}) {
          id
          seoDescription
          seoTitle
        }
      }
    `,
  );
  return (
    <BaseLayout>
      <SEO title={dataJson.seoTitle} description={dataJson.seoDescription} />
      <NavbarBlock />
      <StyledContainer>
        <SectionPadding py="shorter">
          <ContactInteriorHeadlineBlock />
        </SectionPadding>
      </StyledContainer>
      <ContactContactSectionBlock />
      <FooterBlock />
    </BaseLayout>
  );
};

export default SecondPage;

const StyledContainer = styled.div`
  h1 {
    margin-top: ${({theme}) => theme.spaces[9]}px;
  }
`;
