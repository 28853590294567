import React, {useContext} from 'react';
import styled, {ThemeContext} from 'styled-components';
import {useStaticQuery, graphql} from 'gatsby';
import Headline from '@hoffman/shared/src/components/TextFeatures/headline';

const ContactInteriorHeadlineBlock = (props) => {
  const {dataJson} = useStaticQuery<
    GatsbyTypes.ContactInteriorHeadlineBlockQuery
  >(graphql`
    query ContactInteriorHeadlineBlock {
      dataJson(slug: {eq: "contact"}) {
        slug
        panels(name: "contactInteriorHeadlinePanel") {
          name
          models {
            type
            fields {
              text
              url
              newTab
            }
          }
        }
      }
    }
  `);
  const items = dataJson.panels[0].models.reduce(
    (acc: object, m: {type: string; fields: any}) => {
      acc[m.type] = m.fields;
      return acc;
    },
    {},
  );
  const theme = useContext(ThemeContext);

  return (
    <StyledContainer>
      <Headline text={items.headline?.text} size={`${theme.fontSizes[5]}px`} />
    </StyledContainer>
  );
};

const StyledContainer = styled.div`
  margin-top: ${({theme}) => theme.spaces[9]}px;
`;

export default ContactInteriorHeadlineBlock;
