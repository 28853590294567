import React, {useState, useRef, FormEvent} from 'react';
import styled from 'styled-components';
import {RightArrow} from '../Arrow/arrow';

export interface InputProps {
  placeholder: string;
  placeholderColor?: string;
  borderColor: string;
  displaySetting: string;
  required?: boolean;
  arrow?: boolean;
  elProps?: React.ComponentPropsWithRef<'input'>;
}

export interface ArrowFormProps extends InputProps {
  onSubmit?: (value: string) => void;
}

const InputBox = styled.input<InputProps>`
  display: ${(props: InputProps) => props.displaySetting};
  background-color: transparent;
  width: 100%;
  line-height: inherit;
  font-family: Roboto;
  border: 0px;
  font-size: 20px;
  font-weight: 300;
  padding: 8px 0px;

  /* get rid of weird mobile design from iphone */
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  &::placeholder {
    color: ${(props: InputProps) => props.placeholderColor};
  }
`;

const InputContainer = styled.div`
  display: flex;
  border-bottom: 2px solid ${(props: InputProps) => props.borderColor};
  a {
    display: flex;
    align-items: center;
  }
`;

// just a dumb input, must be inside a form to be useful
// todo: add evnts and ref forwarding
export const Input = (props: InputProps) => (
  <InputContainer borderColor={props.borderColor}>
    <InputBox
      as="input"
      variant="input"
      placeholder={props.placeholder + (props.required === true ? '*' : '')}
      placeholderColor={props.placeholderColor}
      {...props.elProps}
    />

    {props.arrow && <RightArrow color="grey" size="20" />}
  </InputContainer>
);

const extractFormData = (form: HTMLFormElement): Object => {
  const entries = Array.from(new FormData(form).entries());
  return Object.fromEntries(entries);
};
export const ArrowForm = ({
  placeholder,
  required,
  onSubmit,
  ...props
}: ArrowFormProps) => {
  const handleSubmit = (event: FormEvent) => {
    event.preventDefault();
    const formData = extractFormData(event.currentTarget as HTMLFormElement);
    onSubmit && onSubmit(JSON.stringify(formData));
  };

  return (
    <form onSubmit={handleSubmit}>
      <InputContainer borderColor={props.borderColor}>
        <InputBox
          name="value"
          placeholder={`${placeholder}${required ? '*' : ''}`}
          placeholderColor={props.placeholderColor}
        />
        <RightArrow type="submit" color="grey" size="20" />
      </InputContainer>
    </form>
  );
};

Input.displayName = 'Input';

Input.defaultProps = {
  displaySetting: 'block',
  placeholderColor: 'black',
  borderColor: '#414448',
  required: false,
  arrow: false,
};

export default Input;
