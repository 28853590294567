export interface signupData {
  firstName: string;
  lastName: string;
  email: string;
  utm_source: string;
  utm_medium: string;
  utm_campaign: string;
}

export interface signupResponse {
  success: boolean;
  err?: any;
}

const url = '/.netlify/functions/signup';

const signup = (data: signupData): Promise<signupResponse> => {
  return fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
    body: JSON.stringify(data),
  })
    .then((resp) => {
      return {success: resp.ok};
    })
    .catch((err) => {
      return {success: false, err};
    });
};

export default signup;
